.root {
	padding: 1.25rem;

	h1 {
		margin: 1rem 1.5rem 0rem 0;
		font-size: 2rem;
		font-weight: bold;
	}
	
	p {
		line-height: 1.1;
		a {
			font-weight: bold;
			text-decoration: underline;
			color: white;
		}
	}

	p:first-of-type {
		padding-top: 1rem;
		// border-top: .25rem solid white;
		margin-top: .75rem;
	}

	p:last-child {
		padding-bottom: 1rem;
		// border-bottom: .25rem solid white;
		margin-bottom: 1rem;
	}

	.sosSample {


		background: rgba(0,0,0,0.5);
		padding: 1rem;
		border-radius: 0.33rem;
		line-height: 1.1;

		margin-bottom: 1rem;

		code {
			white-space: pre-wrap;
			font-size: .75rem;
			a {
				color: inherit;
			}
		}
	}



	a {
		text-decoration: underline;
	}
}