@import './_include-media';
@import './common';


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

body, html, body >:global(#root) {
	height:100%;
	overflow: auto;
	display: flex;
	flex-direction: column;

	font-family: 'Roboto', $font-family-base;

	// $mask: linear-gradient(to bottom,
	// 		rgba(0, 0, 0, 0) 0rem,
	// 		rgba(0, 0, 0, 1) 0rem,
	// 		rgba(0, 0, 0, 1) 95.5%,
	// 		rgba(0, 0, 0, 0) 100%);

	// mask-image: $mask;
	// -webkit-mask-image: $mask;
}

body {
	min-height: 100%; // min-height required for safari
	height: auto; // required for safari

	background-image: linear-gradient(to bottom, #c0454c, #90335a, #4b235d, #312783, #29235C ) !important;
}


// Modify text muted to comply with our styling
:global(.text-muted) {
	// !important necessary to override bootstrap's _text.scss:19 decl
	color: $content-color !important; //transparentize($content-color, .55) !important;
	filter: opacity(.55);
	line-height: 1.2;
}

:global(.btn) {
	color: transparentize($content-color, .2);
}

html {
	font-size: 30px;
}

@include media('retina2x') {
	html {
		font-size: 24px;

		@media (min-width: 768px) {
			font-size: 32px;
		}
	}
}

@include media('retina3x') {
	html {
		font-size: 24px;
	}
}

/**************************************************************************/

// Per https://css-tricks.com/snippets/css/turn-off-number-input-spinners/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/**************************************************************************/

input[type=color] {
	min-height: 2.35rem;
	padding: 0.25rem;
}

/**************************************************************************/


:global(.btn) {
	@include stylize-button($content-bg);

	&:global(.btn-success) {
		@include stylize-button($brand-primary);
	}
}

/**************************************************************************/

:global(a) {
	color: lighten($brand-primary, 20%);
	&:hover, &:active, &:focus {
		color: lighten($brand-primary, 5%);
	}
}